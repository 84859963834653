import socketIOClient from "socket.io-client";

import api from "../api";
import { BASE_URL } from "../constants";

let socket;
const REQUEST_FAILED_MSG =
  "We are having trouble connecting, please check your internet connection if this issue persists.";

export function getSocketInstance() {
  var token = window.localStorage.getItem("token");

  if (!socket?.connected)
    socket = socketIOClient.connect(BASE_URL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: token,
            channel: "MOBILE_WEB",
          },
        },
      },
    });

  return socket;
}

export async function fetchDispute(disputeId) {
  let res = {};

  try {
    const { data } = await api.get(`/disputes/${disputeId}/arguments`);
    res = data;
  } catch ({ request, response }) {
    let msg = REQUEST_FAILED_MSG;

    if (request) msg = request?.responseText || msg;
    else if (response) msg = response?.data?.msg || msg;

    res.code = "01";
    res.msg = msg;
  } finally {
    return res;
  }
}

function handleRequestFailed({ request, response }) {
  let msg = REQUEST_FAILED_MSG;

  if (request) msg = request?.responseText || msg;
  else if (response) msg = response?.data?.msg || msg;

  return { code: "01", msg };
}

async function makeAuthRequest(url, postData) {
  let res = {};

  try {
    const { data } = await api.post(url, postData);
    res = data;
  } catch (error) {
    res = handleRequestFailed(error);
  } finally {
    // this line of code is here because the actual message that users need in cases where something went wrong from the
    // request is sometimes hiddend under the error.description prop of the response form the server
    res.msg = res.error?.description || res.msg;

    return res;
  }
}

export function loginWithCredentials(postData) {
  return makeAuthRequest("/auth/login-with-cred", postData);
}

export async function loginWithToken(postData) {
  return makeAuthRequest("/auth/login", postData);
}

export async function requestOTPSignup(postData) {
  return makeAuthRequest("/auth/request-otp-signup", postData);
}

export async function verifyOTP(postData) {
  return makeAuthRequest("/auth/verify-otp", postData);
}

export async function setNewPIN(postData) {
  return makeAuthRequest("/auth/set-password", postData);
}

export async function resetPin(postData) {
  return makeAuthRequest("/auth/forgot-pin", postData);
}

export function persistAuthState(data) {
  localStorage.setItem("data", JSON.stringify(data.data));
  localStorage.setItem("token", data.token);
}
