import React, { useState } from "react";
import { connect } from "react-redux";
import toast from "toasted-notes";
import classnames from "classnames";

import { setNewPIN } from "../../common";
import "../../auth.css";
import { HOME } from "../../constants/routeNames";

function SetNewPin({ user, history }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  async function submitForm(e) {
    e.preventDefault();

    if (!password) return toast.notify("Please Enter a new PIN");

    if (!confirmPassword) return toast.notify("Please Re-Enter your PIN");

    if (password.length !== 4 || confirmPassword.length !== 4)
      return toast.notify("PIN can only be 4 numbers");

    if (isNaN(password) || isNaN(confirmPassword))
      return toast.notify("Only DIGITS are allowed for PIN");

    if (password !== confirmPassword)
      return toast.notify("Make sure the PIN entered are the same");

    setLoading(true);
    const res = await setNewPIN({ password });
    setLoading(false);
    console.log("SET NEW PIN RES", res);

    if (res.code !== "00") return toast.notify(res.msg);

    history.push(HOME);
  }

  return (
    <div id="set-new-pin" className="auth">
      <form onSubmit={submitForm}>
        <div className="welcome-texts">
          <h3>Dispute Center</h3>
          <p>Hi {user.name}, please set a 4-digit PIN for your account.</p>
        </div>

        <div>
          <div className="input-fields">
            <div>
              <label>New PIN</label>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
                placeholder="Enter New PIN"
                type="password"
              />
            </div>

            <div>
              <label>Confirm PIN</label>
              <input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value.trim())}
                placeholder="Re-Enter you new PIN"
                type="password"
              />
            </div>
          </div>

          <button className={classnames("submit", { loading })}></button>
        </div>
      </form>
    </div>
  );
}

function mapStateToProps({ auth }) {
  return { user: auth.user };
}

export default connect(mapStateToProps)(SetNewPin);
