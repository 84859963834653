import { LOGIN, LOGOUT } from "../types";

const INITIAL_STATE = { user: getUser() };

function getUser() {
  const token = localStorage.getItem("token");
  if (!token) return null;

  const data = localStorage.getItem("data");
  if (!data) return null;

  return JSON.parse(data);
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN: {
      return { user: action.payload };
    }
    case LOGOUT: {
      return { ...INITIAL_STATE };
    }
    default:
      return state;
  }
}
