import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import ReactPaginate from "react-paginate";

import { fetchDisputes, fetchDisputesStats } from "../../redux/actions";
import Card from "./Card";
import { LOGOUT, HOME } from "../../constants/routeNames";
import DateRangePicker from "../../components/DateRangePicker";

function Home({
  user,
  fetchDisputes,
  allDisputes,
  stats,
  fetchDisputesStats,
  nav,
  loadingDisputes,
}) {
  const handle = useRef(null);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [status, setStatus] = useState(
    localStorage.getItem("status") || "OPEN"
  );
  const dropDownRef = useRef(null);

  function changelocalStatus(status) {
    localStorage.setItem("status", status);
    setStatus(status);
  }

  function onPageChange(arg) {
    const { selected } = arg;
    console.log("PAGE CHANGE", arg);

    fetchDisputes({ page: selected + 1, size: 10, status });
  }

  // useEffect(() => {
  //   function handler(e) {
  //     setShowDropdown(false);
  //   }

  //   window.addEventListener("click", handler, true);

  //   return window.removeEventListener("click", handler);
  // });

  useEffect(() => {
    let params = { status };
    let delay = 0;

    // for search queries, we want to debounce the api request for some few milliseconds so that we do not
    // make too many requests to the server on every keystroke
    if (search.trim()) {
      params.search = search;
      delay = 500;
    }

    const start_date = startDate?.toLocaleDateString("en-GB");
    const end_date = endDate?.toLocaleDateString("en-GB");

    if (startDate && endDate) params = { ...params, start_date, end_date };
    else if (startDate && !endDate)
      params = { ...params, start_date, end_date: start_date };

    // clear any existing timeouts before you create a new one to prevent some funny behaviours
    if (handle.current) clearTimeout(handle.current);

    handle.current = setTimeout(() => {
      fetchDisputes(params);
    }, delay);
  }, [status, search, startDate, endDate, fetchDisputes]);

  useEffect(() => {
    fetchDisputesStats();
  }, [fetchDisputesStats]);

  const hasResults = allDisputes && Object.keys(allDisputes).length > 0;

  return (
    <div id="home" className="wrapper">
      <div className="head">
        <Link to={HOME}>
          <img
            className="logo"
            src={require("../../assets/img/move_logo.png")}
            alt=""
          />
        </Link>

        <div className="dropdown-container">
          <div
            ref={dropDownRef}
            onClick={() => {
              console.log("test");
              setShowDropdown(!showDropdown);
            }}
            className="user-dropdown-container"
          >
            <div className="user-dropdown">
              <img src={require("../../assets/img/user.svg")} alt="" />
            </div>

            <div className="username-container">
              <p onClick={() => setShowDropdown(!showDropdown)}>{user.name}</p>
              <i className="fas fa-caret-down"></i>
            </div>
          </div>

          <div
            className={classnames("dropdown-links", { hidden: !showDropdown })}
          >
            {showDropdown ? (
              <Fragment>
                <Link to="/">Back to home</Link>
                <Link to={LOGOUT}>Logout</Link>
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>

      <div className="cases-container">
        <div className="top-section">
          <div className="cases-btn-cont">
            <div
              className={classnames("open-cases", "case-btn", {
                active: status === "OPEN",
              })}
              onClick={() => changelocalStatus("OPEN")}
            >
              <p>Open Cases</p>
              <div className="num">
                <span>{stats?.open_disputes}</span>
              </div>
            </div>

            <div
              className={classnames("open-cases", "case-btn", {
                active: status === "CLOSED",
              })}
              onClick={() => changelocalStatus("CLOSED")}
            >
              <p>Closed Cases</p>
              <div className="num">
                <span>{stats?.closed_disputes}</span>
              </div>
            </div>
          </div>

          <div className="filter-cont">
            <input
              className="search-field"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="search by ID eg: MS2PUXHB"
            />

            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </div>
        </div>

        <div className="cards">
          {loadingDisputes ? (
            <div className="loader">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            Object.values(allDisputes || {}).map((i) => {
              return <Card dispute={i} user={user} key={i.id} />;
            })
          )}

          {!hasResults ? (
            <div className="no-results">No Results Found</div>
          ) : null}
        </div>
      </div>

      <div className="pagination-container">
        {nav && hasResults ? (
          <Fragment>
            <p>
              Showing {nav.current_page} to {Object.keys(allDisputes).length} of{" "}
              {nav.total_records} entries
            </p>

            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              initialPage={nav.current_page - 1}
              pageCount={nav.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              disableInitialCallback={true}
              onPageChange={onPageChange}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </Fragment>
        ) : null}
      </div>
    </div>
  );
}

function mapStateToProps({ auth, disputes }) {
  return {
    user: auth.user,
    allDisputes: disputes.all,
    stats: disputes.stats,
    nav: disputes.nav,
    loadingDisputes: disputes.loading,
  };
}

export default connect(mapStateToProps, { fetchDisputes, fetchDisputesStats })(
  Home
);
