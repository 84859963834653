import {
  LOGIN,
  FETCH_DISPUTES,
  FETCH_DISPUTE_STATS,
  CLOSE_CASE,
  REQUEST_MEDIATOR,
  APPEAL_CASE,
  SET_LOADING,
  ADD_ARGUMENT,
  SET_ARGUMENT_DELIVERED,
  LOGOUT,
} from "../types";
import api from "../../api";

export function login(user) {
  return { payload: user, type: LOGIN };
}

export function fetchDisputes(params = {}) {
  return async function (dispatch) {
    dispatch({ type: SET_LOADING, payload: true });

    try {
      const { data } = await api.get(`/disputes/`, { params });

      if (data.code === "00") {
        // convert all arguments prop values to an array instead of null
        data.data = data.data.map((dispute) => ({ ...dispute, arguments: [] }));
        dispatch({ type: FETCH_DISPUTES, payload: data });
      }
    } catch (error) {
      console.log("DISPUTES ERROR", error);
    } finally {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
}

export function fetchDisputesStats() {
  return function (dispatch) {
    api
      .get("/disputes/stats")
      .then(({ data }) => {
        if (data.code === "00") {
          dispatch({ type: FETCH_DISPUTE_STATS, payload: data.data });
        }
      })
      .catch((err) => {
        console.log("STATS ERROR", err);
      });
  };
}

// function modifyDispute(disputeId, args) {
//   return api.post(`/disputes/${disputeId}/add-argument`, { ...args });
// }

export function closeDispute(disputeId) {
  return { type: CLOSE_CASE, payload: disputeId };
}

export function requestMediator(disputeId) {
  return { type: REQUEST_MEDIATOR, payload: disputeId };
}

export function appealCase(disputeId, msg) {
  return { type: APPEAL_CASE, payload: disputeId };
}

export function addArgument(payload) {
  return { type: ADD_ARGUMENT, payload };
}

export function setArgumentDeliveredStatus(payload) {
  return { type: SET_ARGUMENT_DELIVERED, payload };
}

export function logout() {
  window.localStorage.clear();
  return { type: LOGOUT, payload: null };
}
