import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import toast from "toasted-notes";
import classnames from "classnames";

import { requestOTPSignup } from "../../common";
import "../../auth.css";

function SignUp({ user, history }) {
  const [msisdn, setMsisdn] = useState("");
  const [loading, setLoading] = useState(false);

  async function submitLoginForm(e) {
    e.preventDefault();

    if (!msisdn) return toast.notify("Please Enter Phone Number");

    setLoading(true);
    const res = await requestOTPSignup({ msisdn });
    setLoading(false);
    console.log("SIGN UP RES", res);

    if (res.code !== "00") return toast.notify(res.msg);

    history.push(`/verify-otp/${msisdn}`);
  }

  return (
    <div id="signup" className="auth">
      <form onSubmit={submitLoginForm}>
        <div className="welcome-texts">
          <h3>Dispute Center</h3>
          <p>Registration</p>
        </div>

        <div>
          <div className="input-fields">
            <div>
              <label>Phone Number</label>
              <i className="far fa-user-circle"></i>
              <input
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value.trim())}
                placeholder="Enter Phone Number"
              />
            </div>
          </div>

          <button className={classnames("submit", { loading })}></button>

          <div className="alternate-links">
            <Link to="/">back to login</Link>
          </div>
        </div>
      </form>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps)(SignUp);
