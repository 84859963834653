import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import Swal from "sweetalert2";
import toast from "toasted-notes";

import { getSocketInstance, fetchDispute } from "../../common";

import Argument from "./Argument";
import Card from "./Card";
import { CLOSE_CASE, APPEAL_CASE, REQUEST_MEDIATOR } from "../../constants";
import { HOME } from "../../constants/routeNames";
import { Link } from "react-router-dom";

const REQUEST_FAILED_MSG = "Request Failed, try again soon";

function mutateDisputeLocally(type, payload) {
  if (type === CLOSE_CASE) {
    payload.status = "CLOSED";
  } else if (type === APPEAL_CASE) {
    payload.decision_reached = true;
  } else if (type === REQUEST_MEDIATOR) {
    payload.mediator_requested = true;
  }
  return payload;
}

function Chat({ history, user, match }) {
  const [dispute, setDispute] = useState(null);
  const [message, setMessage] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const argumentsRef = useRef(null);
  const messageRef = useRef(null);

  const disputeId = match.params?.disputeId;

  /**
   * only use this state if the error is related to fetching the dispute, do not use this for situations
   * when there's an error with a message sent
   * For erros related to message delivery, just show a toast notification with toast.notify, no need to involve state change
   */
  const [error, setError] = useState("");

  /**
   *  why do we have so many optional chaining here ?
   *  This is because before the request for the dispute and it's
   *  details are ready, the dispute will be null for sometime
   */
  const escrow = dispute?.escrow;
  const id = dispute?.id;
  const recipient = escrow?.recipient;
  const sender = escrow?.sender;
  const case_closed = dispute?.status === "CLOSED";

  const isBuyer = sender?.id === user.id;

  function scrollToBottom() {
    setTimeout(() => {
      if (argumentsRef.current) {
        setTimeout(() => {
          // on chrome for mobile, the address bar within which the url shows is also calculated as
          // as part of the scroll Height and as such we need to scroll that out so the
          // input field can fully show

          // document.getElementById("chat-meta-container").scroll({ top: 100 });

          const messageField = document.getElementById("message-field");

          console.log("GGG", messageField);
          if (messageField)
            messageField.scrollTo = argumentsRef.current.scrollHeight + 300;
          // document.getElementById("chat").requestFullscreen();

          argumentsRef.current.scrollTop = argumentsRef.current.scrollHeight;
        }, 10);
      }
    }, 10);
  }

  useEffect(() => {
    if (dispute) {
      // join the currently opened dispute's chat room so you can get updates from that chat room
      getSocketInstance().emit("join", dispute.id);

      // leave the chat room when this component unmounts
      return () => {
        getSocketInstance().emit("leave", dispute.id);
      };
    }
  }, [dispute]);

  useEffect(() => {
    // show toast notifications when an error occurs
    if (error.trim()) toast.notify(error, { duration: null });
  }, [error]);

  const emitSocketMessage = useCallback(
    (data) => {
      const dataToSend = {
        ...data,
        type: data.type || data.description,
        created_at: new Date(),
        dispute_id: dispute.id,
        senderID: user.id,
        recipientID: dispute.escrow.recipient.id,
        user_id: user.id,
        clientId: Math.random() + Math.random() * Math.random(),
        client: "MOBILE_WEB",
        room: dispute.id,
      };

      getSocketInstance().emit("send chat", dataToSend);

      let payload = {
        ...dispute,
        arguments: [
          ...dispute.arguments,
          {
            ...dataToSend,
            modified_at: "None",
            user,
          },
        ],
      };

      payload = mutateDisputeLocally(dataToSend.type, payload);
      setDispute(payload);
      scrollToBottom();
    },
    [dispute, user]
  );

  const handleChatUpdate = useCallback(
    (data) => {
      console.log("Chat Update running", dispute);
      console.log("Update Chat Response", data);

      // this is a patch that must be resolved later, my guess is the socketio is not able to clean up properly and as such,
      // we keep falling into situations where dispute is null for some split milliseconds, probably due to stale data still
      // beign held by a previous version of this same function
      if (!dispute) return;

      const returnedArgument = data.data;
      const clientId = returnedArgument.clientId;
      const isSender = returnedArgument.user.id === user.id;

      // if the currently opened chat has nothing to do with the update received, ignore
      if (returnedArgument.disputeId !== dispute.id) return;

      if (data.code !== "00") {
        const msg =
          "Operation Failed: " +
          (data?.error?.description || data.msg || REQUEST_FAILED_MSG);

        toast.notify(msg, {
          duration: null,
          type: "error",
        });

        if (isSender) {
          setDispute({
            ...dispute,
            arguments: dispute.arguments.map((i) =>
              i.clientId === clientId ? { ...i, delivered: false } : i
            ),
          });
        }

        return;
      }

      if (isSender) {
        //  if its the sender of the message, then only update the message delivered status since they
        // they already have the message as an argument in their list of arguments on the dispute object

        setDispute({
          ...dispute,
          arguments: dispute.arguments.map((i) =>
            i.clientId === clientId ? { ...i, delivered: true } : i
          ),
        });
      } else {
        //  if its the receiver of the message, then add the new message to their list of arguments
        // and then scroll the last message into view

        let payload = {
          ...dispute,
          arguments: [
            ...dispute.arguments,
            { ...returnedArgument, delivered: true },
          ],
        };

        payload = mutateDisputeLocally(returnedArgument.type, payload);
        setDispute(payload);
        scrollToBottom();
      }
    },
    [dispute, user.id]
  );

  useEffect(() => {
    if (!disputeId) return;

    async function run() {
      const res = await fetchDispute(disputeId);

      if (res.code !== "00") return setError(res?.msg || REQUEST_FAILED_MSG);

      const returnedDispute = res.data.dispute;

      returnedDispute.arguments = returnedDispute.arguments.map((argument) => ({
        ...argument,
        delivered: true,
      }));
      setDispute(returnedDispute);
    }

    run();
  }, [disputeId]);

  useEffect(() => {
    getSocketInstance().on("update chat", handleChatUpdate);

    return () => {
      getSocketInstance().off("update chat", handleChatUpdate);
    };
  }, [dispute, handleChatUpdate]);

  useEffect(() => {
    // scroll to the bottom immidiately the response for the dispute and its arguments are in
    if (dispute) {
      scrollToBottom();
    }
  }, [dispute]);

  const requestToAppealCase = useCallback(async () => {
    const { value: msg, isDismissed } = await Swal.fire({
      title: "Enter your reason for appealing this case",
      input: "text",
      showCancelButton: true,
      inputPlaceholder: "Start typing the reason...",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
    });

    if (msg.trim()) {
      emitSocketMessage({
        description: APPEAL_CASE,
        appeal: msg,
      });
    } else if (!msg?.trim() && !isDismissed) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a reason to continue",
      });
    }
  }, [emitSocketMessage]);

  async function requestToCloseCase() {
    const title = isBuyer
      ? "Closing the case will send funds to the merchant"
      : "Closing the case will reverse funds to buyer";

    const { isConfirmed } = await Swal.fire({
      title,
      showCancelButton: true,
      confirmButtonText: `Yes`,
    });

    if (isConfirmed) closeDispute();
  }

  const closeDispute = useCallback(() => {
    emitSocketMessage({ description: CLOSE_CASE });
  }, [emitSocketMessage]);

  const requestMediator = useCallback(() => {
    emitSocketMessage({ description: REQUEST_MEDIATOR });
  }, [emitSocketMessage]);

  const sendChat = useCallback(
    (e) => {
      e.preventDefault();

      if (!message.trim()) return;

      emitSocketMessage({ description: message, type: "DEFAULT" });

      setMessage("");
    },
    [emitSocketMessage, message]
  );

  if (error && !dispute) {
    return (
      <div id="chat" className="wrapper">
        <div className="error">
          <Link className="retry" to={`/chat/${disputeId}`}>
            Retry
          </Link>
          <Link className="go-back" to={HOME}>
            Go back Home
          </Link>
        </div>
      </div>
    );
  } else if (!dispute) {
    return (
      <div id="chat" className="wrapper">
        <p className="loading">LOADING</p>
      </div>
    );
  }

  return (
    <div id="chat" className="wrapper">
      <div id="chat-meta-container">
        <div className="chat-head">
          <i className="fas fa-arrow-left" onClick={() => history.goBack()}></i>
          <p>{recipient.id === user.id ? sender.name : recipient.name}</p>
          <i
            onClick={() => {
              setShowInfo(!showInfo);
            }}
            className="info fas fa-info-circle"
          ></i>
        </div>

        <div className="action-btns">
          <button
            onClick={requestToCloseCase}
            className={classnames("close-case", {
              showBtn: !case_closed && dispute.mediator_requested === false,
            })}
          >
            Close Case
          </button>

          <button
            onClick={requestToAppealCase}
            className={classnames("appeal-case", {
              showBtn: !case_closed && dispute.decision_reached === true,
            })}
          >
            Appeal Case
          </button>

          <button
            onClick={() => requestMediator(id)}
            className={classnames("request-mediator", {
              showBtn: !case_closed && dispute.mediator_requested === false,
            })}
          >
            Request Mediator
          </button>
        </div>
      </div>

      <Card data={dispute} vissible={showInfo} />

      <div className="arguments" ref={argumentsRef}>
        {dispute.arguments.map((argument) => (
          <Argument
            argument={argument}
            key={argument.id || argument.clientId}
          />
        ))}
      </div>

      {case_closed ? (
        <div className="case-closed-msg">
          messaging not allowed, this dispute has been closed
        </div>
      ) : (
        <form onSubmit={sendChat} className="message-box">
          <input
            id="message-field"
            placeholder="Type your message here..."
            ref={messageRef}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button>
            <i className="fas fa-paper-plane"></i>
          </button>
        </form>
      )}
    </div>
  );
}

function mapStateToProps({ auth }) {
  return { user: auth.user };
}

export default connect(mapStateToProps)(Chat);
